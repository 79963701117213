var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detailbg"},[_c('div',{staticStyle:{"padding-bottom":"80px"}},[_vm._m(0),_c('div',[_c('div',[_vm._m(1),_c('div',{staticStyle:{"margin":"10px 15px","font-size":"18px"}},[_vm._v(" 旅游年票办理 ")]),_c('div',{staticClass:"detailsB"},[_c('span',[_vm._v("其他")]),_c('span',{staticStyle:{"margin":"0px 15px"}},[_vm._v("|")]),_c('van-icon',{attrs:{"name":"clock"}}),_c('span',{staticStyle:{"margin-left":"4px"}},[_vm._v("30分钟")])],1)]),_vm._m(2),_vm._m(3),_c('div',[_c('div',{staticStyle:{"margin":"10px 15px","font-size":"18px","border-bottom":"1px solid #e0e0e0","padding-bottom":"15px"}},[_vm._v("适用门店")]),_c('div',{staticStyle:{"margin":"10px 15px","position":"relative"}},[_vm._m(4),_c('div',{staticClass:"detailsC"},[_c('span',{staticStyle:{"color":"#ff5050","font-size":"22px","margin-right":"15px"}},[_c('van-icon',{attrs:{"name":"location"}})],1),_c('span',{staticStyle:{"color":"#ff5050","font-size":"22px","margin-left":"15px"}},[_c('van-icon',{attrs:{"name":"phone"}})],1)])])])])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../../assets/partymember/dangjian.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detailsA"},[_c('span',[_vm._v("¥ 0")]),_c('span',[_vm._v("¥ 0")]),_c('span',[_vm._v("已售: 788件")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"margin":"10px 15px","font-size":"18px","border-bottom":"1px solid #e0e0e0","padding-bottom":"15px"}},[_vm._v("详情介绍")]),_c('div',{staticStyle:{"margin":"10px 15px","padding-top":"15px"}},[_vm._v(" 办理所需材料 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"margin":"10px 15px","font-size":"18px","border-bottom":"1px solid #e0e0e0","padding-bottom":"15px"}},[_vm._v("购买须知")]),_c('div',{staticStyle:{"margin":"10px 15px","font-size":"15px"}},[_c('div',[_c('span',{staticStyle:{"color":"#999999"}},[_vm._v("付款方式")]),_c('span',{staticStyle:{"padding-left":"15px"}},[_vm._v("线下付款")])]),_c('div',{staticStyle:{"margin-top":"6px"}},[_c('span',{staticStyle:{"color":"#999999"}},[_vm._v("取消预订")]),_c('span',{staticStyle:{"padding-left":"15px"}},[_vm._v("服务开始前24小时可取消")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("光合城晔光小站")]),_c('br'),_c('span',{staticStyle:{"font-size":"13px","color":"#a3a3a3"}},[_vm._v("洛龙区太康东路街道光合城社区")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detailsD"},[_c('div',{staticClass:"detailsDs"},[_c('span',[_vm._v("¥ 0")]),_c('button',[_vm._v("立即预订")])])])
}]

export { render, staticRenderFns }